<template>
  <div class="index gray-bcg-color">
    <div class="banner c-width">
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="(item, index) in bannerData" :key="index">
          <div class="item">
            <div class="item_l">
              <div class="title">{{ item.title }}</div>
              <div class="desc">{{ item.text }}</div>
              <a :href="consoleLoginUrl" class="link-btn" target="_blank"
                >立即体验</a
              >
            </div>
            <img :src="item.src" width="720" alt="" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="live white_bcg-color">
      <div class="live-content c-width">
        <el-image
          :src="require('../../assets/images/index-live.png')"
          alt=""
          width="437"
        ></el-image>
        <div class="v-text">
          <h3 class="title">直播服务</h3>
          <p>
            直播服务依托于微吼多年的经验积累和强大的视频处理能力，为用户提供从接入到分发观看的一站式直播服务，保障用户体验到稳定流畅、低延时、高并发的直播效果。
          </p>
          <a :href="`${domain}/live`" class="link-btn" target="_blank"
            >查看详情</a
          >
        </div>
      </div>
    </div>
    <div class="live">
      <div class="live-content c-width">
        <div class="v-text">
          <h3 class="title">点播服务</h3>
          <p>
            微吼云点播为有点播服务需求的用户提供点播上传、音视频转码、音
            视频存储、CDN加速、iOS／Android／PC多终端播放的一体化解决方案，帮助用户快速完成点播平台的搭建。
          </p>
          <a :href="`${domain}/vod`" class="link-btn" target="_blank"
            >查看详情</a
          >
        </div>
        <el-image
          :src="require('../../assets/images/index-vod.png')"
          width="451"
          lazy
        ></el-image>
      </div>
    </div>
    <div class="live white_bcg-color">
      <div class="live-content c-width">
        <el-image
          :src="require('../../assets/images/index-interact.png')"
          alt=""
          width="437"
        ></el-image>
        <div class="v-text">
          <h3 class="title">音视频互动服务</h3>
          <p>
            提供多人在线实时互动连麦，支持WEB，iOS，android多平台互通，
            与大规模直播无缝对接，多种混流模版输出。
          </p>
          <a :href="`${domain}/interactive`" class="link-btn" target="_blank"
            >查看详情</a
          >
        </div>
      </div>
    </div>
    <div class="service-list c-width">
      <div class="item">
        <div class="img-wrap">
          <img src="../../assets/images/index-doc.png" alt="" width="320" />
        </div>
        <p class="title">文档白板</p>
        <p class="text">
          文档自动转码，并提供了丰富的演示功能， 增强用户的互动性。
        </p>
        <a :href="`${domain}/document`" class="link-btn" target="_blank"
          >查看详情</a
        >
      </div>
      <div class="item">
        <div class="img-wrap">
          <img src="../../assets/images/index-chat.png" alt="" width="320" />
        </div>
        <p class="title">聊天服务</p>
        <p class="text">快速接入聊天服务，实现用户之间消息的实 时传送。</p>
        <a :href="`${domain}/chat`" class="link-btn" target="_blank"
          >查看详情</a
        >
      </div>
      <div class="item">
        <div class="img-wrap">
          <img src="../../assets/images/index-form.png" alt="" width="320" />
        </div>
        <p class="title">表单服务</p>
        <p class="text">多维度数据统计分析，丰富业务场景， 比用户更懂用户。</p>
        <a :href="`${domain}/form`" class="link-btn" target="_blank"
          >查看详情</a
        >
      </div>
    </div>
    <div class="platform white_bcg-color">
      <div class="platform-content c-width">
        <c-title :titleArr="platformTitle"></c-title>
        <ul class="clearfix">
          <li :class="{ 'v-on': isshowBigBanner }" @click="switchPlatform(1)">
            <p class="v-title">
              1天接入
              <span class="v-line"></span>
            </p>
            <p class="v-content">
              完善的SDK/API<br />高完成度的Demo体验，组件化的解决方案<br />使用户无论从0到1搭建<br />还是接入已有产品，都同样方便快捷
            </p>
            <img
              src="../../assets/images/platform1.png"
              alt=""
              class="v-platform-img"
            />
          </li>
          <li :class="{ 'v-on': !isshowBigBanner }" @click="switchPlatform(2)">
            <p class="v-title">
              节省成本
              <span class="v-line"></span>
            </p>
            <p class="v-content">
              丰富完善的功能<br />全面的开发文档，快捷的接入方式<br />一站式的技术服务<br />帮助减少企业90%的开发成本<br />省钱又省力
            </p>
            <img
              src="../../assets/images/platform2.png"
              alt=""
              class="v-platform-img"
            />
          </li>
        </ul>
      </div>
    </div>
    <div class="v-technology c-width">
      <c-title :titleArr="technologyTitle"></c-title>
      <ul class="technology-list">
        <li
          class="technology-item"
          v-for="item in technologyData"
          :key="item.index"
        >
          <el-image :src="item.src" alt="" lazy></el-image>
          <p class="v-title">
            {{ item.title }}
            <span class="v-line"></span>
          </p>
          <div class="v-content">
            <div v-for="(text, index) in item.textData" :key="index">
              {{ text }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="v-service white_bcg-color">
      <div class="v-service-content c-width">
        <div class="v-text">
          <p class="v-title">
            服务全面升级
          </p>
          <p class="v-subtitle">
            及时响应为开发保驾护航
          </p>
          <p class="v-content">
            <el-image
              :src="require('../../assets/images/service1.png')"
              lazy
            ></el-image
            >7*24小时的技术支持
          </p>
          <p class="v-content">
            <el-image
              :src="require('../../assets/images/service2.png')"
              lazy
            ></el-image
            >专属1对1服务
          </p>
          <p class="v-content">
            <el-image
              :src="require('../../assets/images/service3.png')"
              lazy
            ></el-image
            >完善的工单系统
          </p>
        </div>
        <div class="v-imgs">
          <img
            src="../../assets/images/service4.png"
            alt=""
            v-show="isShowService"
          />
          <img
            src="../../assets/images/service5.png"
            alt=""
            v-show="!isShowService"
          />
        </div>
      </div>
    </div>
    <div class="v-cooperation c-width">
      <c-title :titleArr="cooperationTitle"></c-title>
      <div class="cooperation-content">
        <el-image
          v-for="(item, index) in cooperationData"
          :key="index"
          :src="item"
          alt=""
          lazy
        ></el-image>
      </div>
    </div>
    <div class="v-select">
      <div class="v-center">
        <h2 class="v-title">
          选择微吼云－开发稳定的产品
          <span class="v-line"></span>
        </h2>
        <el-image
          :src="require('../../assets/images/select_content.png')"
          alt=""
          lazy
        ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
import title from '../../components/title'
export default {
  components: {
    'c-title': title
  },
  mounted () {
    setInterval(() => {
      this.isShowService = !this.isShowService
    }, 3000)
  },
  data () {
    return {
      consoleLoginUrl: `${window.urlConfig.consoleUrl}loginLayout/login`,
      domain: `//${window.location.host}`,
      platformTitle: ['即刻打造自己的直播平台'],
      technologyTitle: ['10年技术沉淀'],
      cooperationTitle: ['合作用户'],
      bannerData: [
        {
          title: '定义企业直播视频云标准',
          text: '专注于企业视频直播云服务',
          src: require('../../assets/images/index-banner1.png')
        },
        {
          title: '注册即送200元代金券',
          text: '万元开发者扶持基金开放申请中',
          src: require('../../assets/images/index-banner2.png')
        }
      ],
      cooperationData: [
        require('../../assets/images/cooperation1.png'),
        require('../../assets/images/cooperation2.png'),
        require('../../assets/images/cooperation3.png'),
        require('../../assets/images/cooperation4.png'),
        require('../../assets/images/cooperation5.png'),
        require('../../assets/images/cooperation6.png'),
        require('../../assets/images/cooperation7.png'),
        require('../../assets/images/cooperation8.png'),
        require('../../assets/images/cooperation9.png'),
        require('../../assets/images/cooperation10.png'),
        require('../../assets/images/cooperation11.png'),
        require('../../assets/images/cooperation12.png')
      ],
      isShowService: true,
      isshowBigBanner: true,
      technologyData: [
        {
          src: require('../../assets/images/technology1.png'),
          title: '全球CDN布局',
          textData: ['国内上千节点', '覆盖全球范围', '智能CDN调度分发']
        },
        {
          src: require('../../assets/images/technology2.png'),
          title: '海量存储',
          textData: ['海量存储可用', '异地多备高可靠', '简单易用便于管理']
        },
        {
          src: require('../../assets/images/technology3.png'),
          title: '全链路监控',
          textData: ['秒级采集', '一站式监控', '灵活精准预警']
        },
        {
          src: require('../../assets/images/technology4.png'),
          title: '大数据系统',
          textData: ['秒级采集', '快速建模 深度分析']
        },
        {
          src: require('../../assets/images/technology5.png'),
          title: '无限并发',
          textData: ['无上限并发支撑', '千万级实时互动']
        },
        {
          src: require('../../assets/images/technology6.png'),
          title: '大规模实时消息',
          textData: ['视频消息流同步传输', '分层处理', '多重智能过滤']
        },
        {
          src: require('../../assets/images/technology7.png'),
          title: '极致编码传输',
          textData: [
            '极致图像增强音频增益',
            '高压缩比和自适应视频压缩',
            '最大化传输效率'
          ]
        },
        {
          src: require('../../assets/images/technology8.png'),
          title: '智能AI',
          textData: ['提供文字、语音、图像、视频', '等智能AI应用']
        }
      ]
    }
  },
  methods: {
    switchPlatform (index) {
      this.isshowBigBanner = index === 1
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/less/varible.less';
h2,
h3 {
  margin: 0;
  padding: 0;
}
.index {
  .title {
    font-size: 30px;
    font-weight: 500;
    color: #555;
    text-align: center;
    text-align: left;
  }

  .banner {
    height: 546px;

    /deep/ .el-carousel__container {
      height: 504px;
    }

    /deep/ .el-carousel__indicators {
      margin-top: 10px;
    }

    /deep/ .el-carousel__indicator {
      &.is-active {
        .el-carousel__button {
          opacity: 0.5;
        }
      }

      /deep/ .el-carousel__button {
        width: 50px;
        height: 4px;
        border-radius: 2px;
        background: #000;
        opacity: 0.3;
      }
    }

    .item {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      // border: 1px solid black;

      .item_l {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
          font-size: 42px;
          color: #333;
        }

        .desc {
          margin-top: 20px;
          font-size: 22px;
          color: #333;
        }

        .link-btn {
          margin-top: 50px;
          &:hover {
            background: #fff;
            color: @mainColor;
          }
        }
      }
    }
  }
  .live-content {
    display: flex;
    justify-content: space-between;
    padding: 75px 0;
    .v-text {
      margin: 0 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        width: 480px;
        word-break: break-all;
        color: #666;
        margin: 25px 0 50px;
        font-size: 16px;
      }
    }
  }

  .service-list {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 30px;
    margin-top: 180px;

    .item {
      margin-bottom: 40px;
      position: relative;
      border-radius: 4px;
      box-shadow: @boxShadow;
      background: #fff;
      padding: 0 38px 40px;
      font-size: 16px;
      color: #666;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .img-wrap {
        width: 100%;
        height: 140px;
        position: relative;
        img {
          width: 100%;
          position: absolute;
          top: -140px;
        }
      }

      .text {
        margin: 10px 0 25px;
        text-align: center;
      }

      .title {
        margin-top: 10px;
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 4px;
        background: @mainColor;
        opacity: 0.2961;
        border-radius: 4px;
        position: absolute;
        bottom: 0;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  .platform-content {
    padding: 40px 0;
    ul {
      margin-top: 40px;
      padding-left: 32.5px;
      li {
        float: left;
        width: 415px;
        height: 205px;
        padding: 25px 40px;
        box-shadow: 0px 0px 10px 0px rgba(59, 127, 186, 0.6);
        border-radius: 8px;
        position: relative;
        margin-top: 75px;
        cursor: pointer;
        transition: all 0.5s ease-in-out;

        &:first-child {
          background: linear-gradient(
            90deg,
            rgba(1, 120, 240, 1),
            rgba(0, 175, 247, 1)
          );

          img {
            width: 255px;
          }
        }

        &:last-child {
          background: linear-gradient(
            90deg,
            rgba(29, 47, 104, 1),
            rgba(57, 81, 155, 1)
          );

          img {
            width: 235px;
          }
        }

        .v-title {
          font-size: 24px;
          color: #fff;
        }

        .v-line {
          display: block;
          width: 42px;
          height: 4px;
          background-color: #fff;
          border-radius: 2px;
          margin-top: 10px;
        }

        .v-content {
          font-size: 14px;
          color: #fff;
          margin-top: 10px;
          line-height: 24px;
        }

        .v-platform-img {
          position: absolute;
          right: 0;
          bottom: 0;
        }

        &.v-on {
          width: 560px;
          height: 280px;
          margin-top: 0;
          z-index: 2;

          .v-title {
            font-size: 30px;
            font-weight: 500;
          }

          .v-content {
            font-size: 16px;
            margin-top: 18px;
            font-weight: 500;
          }

          img {
            transition: all 0.5s ease-in-out;
            width: 320px;
          }
        }
      }
    }
  }
  .v-technology {
    padding-top: 40px;
  }
  .technology-list {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 30px;
    margin: 40px 0;

    .technology-item {
      padding: 36px 28px;
      background: #fff;
      text-align: center;
      border-bottom: 4px solid #dde6f3;
      border-radius: 4px;

      .v-title {
        margin-top: 13px;
        font-size: 20px;
        color: #555;
        font-weight: 500;

        .v-line {
          margin: 6px auto 5px;
          display: block;
          width: 36px;
          height: 3px;
          border-radius: 2px;
          background: #555;
        }
      }

      .v-content {
        font-size: 14px;
        color: #666;
      }

      &:hover {
        border-bottom-color: @mainColor;
        box-shadow: @boxShadow;
      }
    }
  }

  .v-service-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 70px;
    height: 430px;
    overflow: hidden;

    .v-title {
      font-size: 34px;
      color: #333;
      font-weight: 500;
    }

    .v-subtitle {
      margin: 10px 0 20px;
      font-size: 26px;
      color: #555;
    }

    .v-content {
      margin-bottom: 10px;
      font-size: 16px;
      color: #666;
      display: flex;
      align-items: center;

      /deep/ .el-image {
        margin-right: 10px;
      }
    }

    .v-imgs {
      img:last-child {
        margin-right: 61px;
      }
    }
  }
  .v-cooperation {
    margin-top: 40px;
  }
  .cooperation-content {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 20px;
    margin: 40px 0;

    img {
      width: 100%;
      box-shadow: @boxShadow;
    }
  }

  .v-select {
    width: 100%;
    height: 500px;
    background: url('../../assets/images/select_bg.png') no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    .v-title {
      padding-top: 38px;
      font-size: 34px;
      color: #fff;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: center;

      .v-line {
        display: block;
        width: 60px;
        height: 6px;
        background: #fff;
        border-radius: 4px;
        margin: 20px 0;
      }
    }

    /deep/ .el-image {
      margin: 50px 0;
    }

    .v-yun-btn {
      background: rgba(0, 0, 0, 0);
      border: 1px solid #fff;
      box-sizing: border-box;
      &:hover {
        background: #fff;
        color: @mainColor;
      }
    }

    .v-center {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
.link-btn:hover {
  background: #fff;
  color: @mainColor;
}
</style>
